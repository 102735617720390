import { CallScriptService } from '@appServices/core-crm/call-script/call-script.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import { Router } from '@angular/router';
import {Customer} from '@appModels/core-crm/customer/customer';
import {timer} from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import * as fromSelectors from '@appStore/selectors';
import * as fromReducer from '@appStore/reducers';

import { CustomerReset, GetCustomers } from '@appStore/actions/core-crm/customer/customer.actions';
import {AuthService} from '@appServices/core-auth/auth/auth.service';

import {modulesArray} from '../../config/nav';
import {Module} from '@appModels/core-setup/module/module';
import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import {TenantCallService} from '@appServices/core-crm/call/tenant-call-type/tenant-call-type';
import {CreateCall} from '@appModels/core-crm/create-call/create-call';
import {CreateCallService} from '@appServices/core-crm/call/create-call/create-call.service';
import {ToastrService} from 'ngx-toastr';
import {CustomerService} from '@appServices/core-crm/customer/customer.service';
import {TranslateService} from '@ngx-translate/core';
import { SearchPages } from '@appStore/actions/core-knowledge/page/page.actions';
import {RepairCentre} from '../../models/core-aftersales/repaircentre';
import {UserService} from "@appServices/core-auth/user/user.service";
import { CallDispositionService } from '@appServices/core-crm/call/call-disposition/call-disposition.service';
import { PortableService } from '@appServices/core-crm/portable/portable.service';
import * as moment from "moment/moment";
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {WebNotificationService} from "@appServices/core-ticketing/web-notification.service";
import {WebNotification} from "@appModels/core-notification/web-notification";
import {CallTicketRequest} from "@appModels/ticketing/call-ticket-request";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}


@Component({
  selector: 'app-main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})

export class MainComponent implements OnInit, OnDestroy {

  timerStarted = false;
  customerSubscribeRanFor = null;

  viewTicketModalReference: BsModalRef;
  @ViewChild('viewTicketModal') public viewTicketModal;


  menu$: Observable<Module[]>;
  customers$: Observable<Customer[]>;
  customer$: Observable<Customer>;
  modalRef: BsModalRef;
  routerUrl$: Observable<{}>;
  subMenu$: Observable<[]>;
  notificationActiveTab: Number = 1;
  isOpen = false;
  isSidebarOpen = false;
  isCountryOpen = false;
  isNotificationOpen = false;
  query = null;
  customerSearchQuery = null;
  timeStart = 0;
  subscribeTimer = 0;

  modules = [];
  tenantCallTypes = [];

  isActivation = false;
  loading = false;
  activeTab: Number = 1;
  openProductStatus: Number = 1;

  createCall: CreateCall = {
    callDurationSeconds: null,
    callGroup: null,
    customerExistsInAtlas: null,
    contractId: null,
    customerId: null,
    level1: null,
    level2: null,
    level3: null,
    notes: null,
    tenantId: null,
    sendPreToken: false,
    id: null,
    accountNumber: null,
    phoneNumber: null,
  };

  formValid = {
    level1: true,
    callGroup: true,
    level2: true,
  };

  myModules = [];
  isInbound = 1;

  callTrayOpen = false;
  showScript = false;

  searchType = 'searchAccountNumber';
  lastCallId=null;

  @Output() isCountryOpenChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output() isOpenChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output() pages = [];

  user = JSON.parse(localStorage.getItem('user')).email;
  tenants = JSON.parse(localStorage.getItem('tenants'));
  tenantName = localStorage.getItem('tenantName');

  searchResults = {
    content: [{
      uniqueIdentifier: null
    }],
    totalElements: null
  };
  timer: any = null;
  enableForm = false;
  disableSaveButton = false;

  repairCentres$: Observable<RepairCentre[]>;
  selectRepairCentreOpen = false;
  repairCentreName: string;
  callGroups: any[];
  level1Calls: any[];
  level2Calls: any[];
  level3Calls: any[];

  customerBasic = {
    customerId: null,
    customerName: null,
    customerTelephone1: null,
    customerTelephone2: null,
    identificationId: null,
    isPortable: false,
    contracts: [{
      contractId: null,
      accountNumber: null
    }]
  };

  customerBasicEmpty = {
    customerId: null,
    customerName: null,
    customerTelephone1: null,
    customerTelephone2: null,
    identificationId: null,
    isPortable: false,
    contracts: [{
      contractId: null,
      accountNumber: null
    }]
  };

  customerBasicContract = [];
  disableNotes: boolean = false;
  disableCallButton: boolean = false;

 isIntroVisible:boolean =true;
 isQuestionVisible:boolean =false;


 callScoreCardTypes = [];
 callScoreCardQuestions = [];
 callScoreCardAnswers = [];
 type: string;
 currentQuestionIndex = 0;
 startQuestion = false;
  callScoreCardQuestionsAnswer: {};
  showTotalScore: boolean;
  scoreSum: number;
  moment:any = moment;

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store<fromReducer.nav.State>,
    private router: Router,
    private renderer: Renderer2,
    private portableService: PortableService,
    private authService: AuthService,
    private permissionResolver: PermissionResolver,
    private tenantCallService: TenantCallService,
    private createCallService: CreateCallService,
    private toastrService: ToastrService,
    private customerService: CustomerService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private userService: UserService,
    private callDispositionService: CallDispositionService,
    private callScriptService:CallScriptService,
    private toastr: ToastrService,
    private ticketService: TicketingService,
    private webNotificationService: WebNotificationService
  ) {

    moment.updateLocale('en', {
      relativeTime: {
        future: "in %s",
        past:   "%s",
        s:  "now",
        m:  "1m",
        mm: "%dm",
        h:  "1h",
        hh: "%dh",
        d:  "1d",
        dd: "%dd",
        M:  "1m",
        MM: "%dm",
        y:  "1y",
        yy: "%dy"
      }
    });

    this.setLang();
    this.userService.updateUserActive().subscribe(data => {
    }, error => {
      console.log(error, 'failed to update user active.')
    });

    this.getUnreadNotifications(this);
    //run every 3 min
    setInterval(this.getUnreadNotifications, 180000 , this);
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  setLang() {
    this.translate.addLangs(['en', 'fr', 'es']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      this.translate.setDefaultLang('en');
      this.translate.currentLang = 'en';
    }
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  clickTab(index) {
    this.activeTab = index;
  }

  openProduct(val) {
    this.openProductStatus = val;
  }

  home() {
    this.router.navigateByUrl(`/home`);
  }

  clicked() {
    if (this.isOpen) {
      this.isOpen = false;
    }
    if (this.isNotificationOpen) {
      this.isNotificationOpen = false;
    }
  }

  clickedHeader() {
    if (this.isNotificationOpen) {
      this.isNotificationOpen = false;
    }
  }

  clickedMain() {
    this.clicked();
    if (this.isSidebarOpen) {
      this.isSidebarOpen = false;
    }
    if (this.isCountryOpen) {
      this.isCountryOpen = false;
    }

  }

  getNameFromRoute = (url) => url.split('/').length > 1 ? url.split('/')[1].toUpperCase() : '';

  logout() {
    localStorage.clear();
    location.reload();
  }

  onNavClick() {
    this.isOpen = !this.isOpen;
    if (this.isSidebarOpen) {
      this.isSidebarOpen = false;
    }
    if (this.isCountryOpen) {
      this.isCountryOpen = false;
    }
  }

  changeTab(id) {
    this.notificationActiveTab = id;
  }

  onClick(path) {
    this.router.navigate([path]);
  }

  onClickRoute(i) {
    console.log(i);
  }

  resetForm() {
    this.createCall.contractId = null;
    this.createCall.customerId = null;
    this.createCall.callDurationSeconds = 0;
    this.createCall.notes = '';
    this.createCall.callGroup = '';
    this.createCall.level1 = '';
    this.createCall.level2 = '';
    this.createCall.level3 = '';
    this.customerBasicContract = [];
    this.customerSearchQuery = null;
    this.level1Calls = [];
    this.level2Calls = [];
    this.level3Calls = [];
    this.showScript = false;
  }

  countrySwitcher() {
    this.isCountryOpen = !this.isCountryOpen;
    // this.isOpen = false
    this.isOpenChange.emit(this.isOpen);
    this.isCountryOpenChange.emit(this.isCountryOpen);
  }

  switchTenant(tenant) {
    this.loading = true;
    localStorage.setItem('tenant', tenant.id);
    localStorage.setItem('tenantName', tenant.name);
    this.authService.saveTenantPref().subscribe(data => {
      window.location.href = '/home';
    });
  }

  profileSwitcher() {
    this.isOpen = !this.isOpen;
    // this.isCountryOpen = false;
    this.isOpenChange.emit(this.isOpen);
    this.isCountryOpenChange.emit(false);
  }


  searchCustomer(query) {
    const sendQuery = {page: 0, size: 10};
    if (query === 'crm' && this.query) {
      this.router.navigateByUrl('/crm/customers?query=' + this.query?.trim() + '&type=' + this.searchType);

      sendQuery[this.searchType] = this.query?.trim();
      this.store.dispatch(new GetCustomers(sendQuery));
    } else if (!this.query) {
      if(this.permissionResolver.have('can-view-customers-list')){
        this.store.dispatch(new GetCustomers(sendQuery));
      } else {
        this.store.dispatch(new CustomerReset())
      }
    }
  }

  searchPage(query) {
    if (query === 'knowledge-base') {
      this.router.navigateByUrl('/knowledge-base/pages/search?searchString=' + this.query?.trim());
      const sendQuery = {page: 0, size: 10};

      sendQuery['searchString'] = this.query?.trim();
      this.store.dispatch(new SearchPages(sendQuery));
    }
  }

  searchCustomerInCall() {
    this.customerBasic = this.customerBasicEmpty;
    const sendQuery = {page: 0, size: 3};

    sendQuery[this.searchType] = (this.customerSearchQuery)?.trim();
    if (this.customerSearchQuery !== null && this.customerSearchQuery !== '') {
      this.customerService.getCustomers(sendQuery).subscribe(data => {
        this.searchResults = data;
        if (this.searchResults.content.length > 0) {
          this.getCustomerBasic(this.searchResults.content[0])
        } else if (this.searchType != 'searchAccountNumber') {
          this.getPortableCustomer(sendQuery);
        }
      });
    }
  }

  getCustomerBasic(customer) {
    this.customerService.getCustomerBasic(customer?.uniqueIdentifier).subscribe(
      data => {
        this.customerBasic = data;
        this.customerBasicContract = data.contracts.filter((ele, index) =>
        data.contracts.findIndex(obj => obj.contractId === ele.contractId) === index);

        if(this.customerBasicContract?.length > 0){
          this.selectCustomer(this.customerBasicContract[0])
          this.router.navigateByUrl(`/crm/customers/${customer?.uniqueIdentifier}`);
        }

      }
    )
  }

  selectCustomer(customer) {
    this.createCall.contractId = customer.contractId;
    this.createCall.accountNumber = customer.accountNumber;
    this.createCall.phoneNumber = this.customerBasic.customerTelephone1;
    this.customerSearchQuery = customer.accountNumber;
  }

  startTimer() {
    if (!this.timerStarted) {
      this.timerStarted = true;
      this.customerBasic = this.customerBasicEmpty;
      this.lastCallId = null;
      this.customer$ = this.store.pipe(select(fromSelectors.getCustomerById));
      this.customer$.subscribe((customer) => {
        if (this.customerSubscribeRanFor != customer?.uniqueIdentifier) {
          this.customerSubscribeRanFor = customer?.uniqueIdentifier;

          if (this.router.url.includes(`/crm/customers/${customer?.uniqueIdentifier}`)) {
            this.customerService.getCustomerBasic(customer?.uniqueIdentifier).subscribe(
              data => {
                this.customerBasic = data;
                this.customerBasicContract = data.contracts.filter((ele, index) =>
                data.contracts.findIndex(obj => obj.contractId === ele.contractId) === index);

                if (this.customerBasicContract?.length > 0) {
                  this.selectCustomer(this.customerBasicContract[0])
                  this.router.navigateByUrl(`/crm/customers/${customer?.uniqueIdentifier}`);
                } else {
                  this.customerSearchQuery = customer.identificationValue;
                  const sendQuery = {page: 0, size: 1};
                  this.searchType = 'searchIdentificationType';
                  sendQuery[this.searchType] = customer.identificationValue;

                  this.getPortableCustomer(sendQuery);
                }
              }
            );
          } else {
            this.customerSearchQuery = null;
            this.searchType = 'searchAccountNumber';
          }
        }
      });
    }


    const source = timer(0, 1000);
    this.enableForm = true;
    this.timer = source.subscribe(val => {
      this.subscribeTimer = this.timeStart + val;
    });
    this.getCallGroups()
    this.getTypes()
    this.getCallQuestions('WELCOME_CALL_SCORE_CARD')

    // this.tenantCallService.getTenantCalls({isInbound: 1, isAnAction: 0}).subscribe(
    //   data => {
    //     this.tenantCallTypes = data;
    //   }
    // );
  }

  getPortableCustomer(query) {
    this.portableService.getPortableCustomers(query).subscribe(
      data => {
        if (data) {
          if (data.content.length > 0) {
            let customer = data.content[0];

            if (!this.router.url.includes(`/crm/customers/${customer?.uniqueIdentifier}`)) {
              this.router.navigateByUrl(`/crm/customers/${customer?.uniqueIdentifier}`);
            }

            this.createCall.phoneNumber = this.customerBasic.customerTelephone1;
            this.customerBasic.isPortable = true;
            this.customerBasic.customerId = customer.id;
            this.customerBasic.customerName = customer.firstName + " " + customer.lastName;
            this.customerBasic.customerTelephone1 = customer.telephone1;
            this.customerBasic.customerTelephone2 = customer.telephone2;
            this.customerBasic.identificationId = customer.identification;

            this.ref.detectChanges();
          }
        }
      }, _ => this.loading = false
    );
  }

  pauseTimer() {
    this.enableForm = false;
    if (this.timer !== null) {
      this.timer.unsubscribe();
      this.subscribeTimer = 0;
    }
  }


  unreadNotifications:WebNotification[] = [];
  readNotifications:WebNotification[] = [];
  onNotificationClick() {
    if (this.isNotificationOpen) {
      this.renderer.removeClass(document.body, 'notif-open');
    } else {
      this.renderer.addClass(document.body, 'notif-open');
    }
    this.isNotificationOpen = !this.isNotificationOpen;

    if (this.isNotificationOpen) {

      this.getUnreadNotifications(this);

      this.webNotificationService.getForUser(true).subscribe(x => {
        this.readNotifications = x;
      })

      this.webNotificationService.clearForCurrentUser().subscribe(x => {})
    }
  }

  getUnreadNotifications(that : any) {
    try {

        that.webNotificationService.getForUser(false).subscribe(x => {
            that.unreadNotifications = x;
            if (that.unreadNotificationsCount != x.length && x.length != 0) {
              let bellIcon = document.getElementById("bell-icon");
              bellIcon?.classList.toggle("swing");

          let circle = document.getElementById('notification-circle');
          circle?.classList.toggle("pulse")

          that.unreadNotificationsCount = x.length
        }
      })
    } catch (err) {

    }
  }

  clickOnNotification(notification: WebNotification) {

    if (notification.targetId != null && notification.webNotificationTypeId != null) {
      if (notification.webNotificationTypeId == 1) {
        this.router.navigateByUrl(`/ticketing/ticket/${notification.targetId}`)
      }
    }

  }



  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'notif-open');
  }

  loadModules() {
    this.menu$.subscribe(modules => {
      this.modules = [];
      modules.forEach(moduleSingle => {

        const filter = modulesArray.filter(e => e.name.toLowerCase() === moduleSingle.name.toLowerCase());
        if (filter.length === 1) {
          this.modules.push({...moduleSingle, ...filter[0]});
        }
      });


      if (this.modules.length > 0) {
        this.myModules = [];
        let userModules = [];
        // if(this.permissionResolver.isTenantAdmin() || this.permissionResolver.isSuperUser()) {
        //   const tenantPreference = localStorage.getItem('tenantPreference');
        //     if (tenantPreference !== null) {
        //         userModules = JSON.parse(tenantPreference).tenantModules;
        //     }
        // } else {
        userModules = this.permissionResolver.getModules();
        // }

        userModules.forEach(userModule => {
          const r = this.modules.filter(e => (e.id === userModule.id || e.id === userModule.parentModuleId));
          if (r.length > 0) {
            this.myModules.push(r[0]);
          }
        });
      }

    }, error => {
      console.log('Error ', error);
    });
  }

  redirectTo(uri: string) {
    window.location.href = uri;
  }

  openViewTicketModal(modal: TemplateRef<any>) {
    this.viewTicketModalReference = this.modalService.show(modal, {class: 'modal-xl'});
  }


  ticketId = null;
  saveCall() {
    delete this.createCall.id;
    this.disableSaveButton = true;
    this.createCall.customerId = this.customerBasic.customerId;
    this.createCall.tenantId = +localStorage.getItem('tenant');
    this.createCall.callDurationSeconds = this.subscribeTimer;

    if (this.customerBasic?.isPortable === false) {
      this.createCall.contractId ? this.createCall.customerExistsInAtlas = true : this.createCall.customerExistsInAtlas = false;
    } else {
      this.createCall.customerExistsInAtlas = true;
    }

    let valid = true;
    Object.keys(this.formValid).forEach(index => {
      this.formValid[index] = (this.createCall[index] !== '' && this.createCall[index] != null);
      if (!this.formValid[index]) {
        valid = false;
      }
    });

    if (valid) {

      this.createCallService.addCall(this.createCall).subscribe(data => {
        if (data) {

          let ticketRequest: CallTicketRequest = {
            callLogId:data.id,
            customerId:this.createCall.customerId,
            contractId:this.createCall.contractId,
            accountNumber:null,
            group:this.createCall.callGroup,
            level1:this.createCall.level1,
            level2:this.createCall.level2,
            level3:this.createCall.level3,
            ticketChannelId:1
          }

          if (this.createCall.accountNumber != null && this.createCall.accountNumber != undefined) {
            ticketRequest.accountNumber = this.createCall.accountNumber.toString();
          }

          this.ticketService.createTicket(ticketRequest).subscribe(x => {
            if (x.id != null && x.id != undefined) {
              this.ticketId = x.id;
              let input = document.getElementById("openTicketButton");
              input?.click();
            }
          })


          this.pauseTimer();
          this.toastrService.success('Customer Call has been successfully logged!', 'Successful!');
          this.resetForm();
          this.disableSaveButton = false;
          this.lastCallId = data.id;
        } else {
          this.disableSaveButton = false;
        }
      });
    } else {
      this.toastrService.error('Please fill mandatory fields!', 'Error');
      this.disableSaveButton = false;
    }
  }

  getCallTypes(isInbound) {
    this.tenantCallService.getTenantCalls({isInbound: isInbound, isAnAction: 0}).subscribe(
      data => {
        this.tenantCallTypes = data;
      }
    );
  }

  getCallGroups() {
    this.callDispositionService.getCallGroups().subscribe(
      data => {
        this.callGroups = data;
      }
    );
  }

  onSelect(){
    this.createCall.level1 = '';
    this.createCall.level2 = '';
    this.createCall.level3 = '';
    if(this.createCall.callGroup == 'Welcome Team') {
      this.getCallAnswers(this.customerBasic.customerId)
    }
  }

  getLevel1Calls() {
    this.callDispositionService.filterlevel1CallsByTenant(this.createCall.callGroup).subscribe(
      data => {
        this.level1Calls = data;
        this.level2Calls = [];
        this.level3Calls = [];
      }
    );
  }

  getLevel2Calls() {
    this.callDispositionService.getLevel2Calls(this.createCall.callGroup, this.createCall.level1).subscribe(
      data => {
        this.level2Calls = data;
        this.level3Calls = [];
      }
    );
  }

  getLevel3Calls() {
    this.callDispositionService.getLevel3Calls(this.createCall.callGroup, this.createCall.level1, this.createCall.level2, this.createCall.contractId).subscribe(
      data => {
        this.level3Calls = data;
      }
    );
  }

  setNotesValidation(){
    this.disableNotes = false;
    if(this.createCall.callGroup == 'Inbound Team' && (this.createCall.level1 == 'Token Issues' || this.createCall.level1 == 'Dropped/Silent Calls')){
      this.disableNotes = true;
    }
    if(this.createCall.callGroup == 'Inbound Team' && this.createCall.level1 == 'Token Issues' && this.createCall.level2 == 'Token Index Issue'){
      this.disableNotes = false;
    }
    if (this.createCall.callGroup == 'KYC Team'){
      this.disableNotes = true;
    }
  }

  getCurrentRepairCentreName() {
    this.repairCentreName = 'NO REPAIR CENTRES ASSIGNED';
    const repairCentre = JSON.parse(localStorage.getItem('repairCentre'));

    if (repairCentre) {
      this.repairCentreName = repairCentre.name;
    } else if (this.repairCentres$) {
      this.repairCentres$.subscribe(repairCentres => {
        if (repairCentres && repairCentres.length > 0) {
          localStorage.setItem('repairCentre', JSON.stringify(repairCentres[0]));
          this.repairCentreName = repairCentres[0].name;
        }
      });
    }
  }

  isRepairCentreUser() {
    return this.permissionResolver.have('can-be-repair-centre-user');
  }

  isAbleToRegisterCustomer(){
    return this.permissionResolver.have('can-create-customer')
  }

  isAbleToRegisterPortableCustomer(){
    return this.permissionResolver.have('can-create-portable-customer')
  }

  showRepairCentreSelection() {
    this.selectRepairCentreOpen = !this.selectRepairCentreOpen;
  }

  selectRepairCentre(repairCentre) {
    const current = JSON.parse(localStorage.getItem('repairCentre'));
    this.selectRepairCentreOpen = false;
    if (!current || (repairCentre && repairCentre.id !== current.id)) {
      localStorage.setItem('repairCentre', JSON.stringify(repairCentre));
      location.reload();
    }
  }

  toUpper(name: string) {
    return name.toUpperCase();
  }

  have(action) {
    return !action || this.permissionResolver.have(action);
  }

  getTypes(){
    this.callScriptService.getCallScoreCardType().subscribe(
      data => {
        this.callScoreCardTypes = data

      }
    )
  }

  getCallQuestions(type) {
    this.callScriptService.getCallQuestions(type).subscribe(
      data => {
        this.callScoreCardQuestions = data;
        this.callScoreCardQuestionsAnswer =  this.callScoreCardQuestions.reduce((acc,next)=> {
          return {
            ...acc,
            [next.questionOrder]: {
              questionId: next.questionId,
              score:0,
              isAnswered: false,
              customerCallScriptQuestionId: null
            }
          }
        }, {})
      },
    );
  }

  getCallAnswers(customerId){
    this.callScriptService.getCallAnswers(customerId, 'WELCOME_CALL_SCORE_CARD').subscribe(
      data => {
        this.callScoreCardAnswers = data;
        this.callScoreCardAnswers.forEach(answer => {
          if(this.callScoreCardQuestionsAnswer[answer.questionOrder]){
            this.callScoreCardQuestionsAnswer[answer.questionOrder].score = answer.score
            this.callScoreCardQuestionsAnswer[answer.questionOrder].isAnswered = true
            this.callScoreCardQuestionsAnswer[answer.questionOrder].customerCallScriptQuestionId = answer.customerCallScriptQuestionId
          }
        })
      }

    )
  }

  saveTotalScore() {
    this.showTotalScore = true;
    // const answers: {score: number, questionId: number}[] = Object.values(this.callScoreCardQuestionsAnswer)
    // this.scoreSum = answers.reduce((acc, nxt) => {
    //   return acc + nxt.score
    // },0)
    // // @TODO save answer in bulk...
    // this.callScriptService.saveBulkScore(this.customerBasic.customerId, answers).subscribe(
    //   data => {
    //     this.toastrService.success('Score saved succesfully!')
    //   }
    // )

  }

  saveScore() {
    const answers: {
      score: number;
      questionId: number;
      isAnswered: boolean;
      customerCallScriptQuestionId: number;
    }[] = Object.values(this.callScoreCardQuestionsAnswer);
    this.scoreSum = answers.reduce((acc, nxt) => {
      return acc + nxt.score;
    }, 0);

    const currentIndex = this.currentQuestionIndex;

    if (answers[currentIndex].isAnswered) {
      this.callScriptService
        .updateScore(
          answers[currentIndex].customerCallScriptQuestionId,
          answers[currentIndex].score
        )
        .subscribe((data) => {
            this.toastr.success(
              "Score for question " +
                this.currentQuestionIndex +
                " updated successfully"
            );
        }
        );
    } else {
      this.callScriptService
        .saveScore(this.customerBasic.customerId, answers[currentIndex])
        .subscribe(() => {
            this.toastr.success(
              "Score for question " +
                this.currentQuestionIndex +
                " saved successfully"
            );
        });
      }

    }

  displayQuestions() {
    this.startQuestion = true;
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.callScoreCardQuestions.length - 1) {
      this.currentQuestionIndex++;
    }
   }

   previousQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
   }

   isLastQuestion():boolean{
    return this.currentQuestionIndex === this.callScoreCardQuestions.length - 1;
   }

   isFirstQuestion():boolean{
    return this.currentQuestionIndex === 0;
   }

   isThirdQuestion():boolean{
    return this.currentQuestionIndex === 2;
   }

   isForthQuestion():boolean{
     return this.currentQuestionIndex === 3;
   }

  ngOnInit() {
    this.routerUrl$ = this.store.pipe(select(fromSelectors.getRouterUrl));
    this.routerUrl$.subscribe(routerUrl => {
      this.customer$ = this.store.pipe(select(fromSelectors.getCustomerById));
      this.customer$.subscribe((customer) => {
      this.disableCallButton = false;
        if(this.router.url.includes(`/crm/customers/${customer?.uniqueIdentifier}`)) {
          if(customer?.customerStatus && customer?.customerStatus['name'] == 'KYC_PENDING'){
            this.disableCallButton = true;
          }
        }
      });
    })



    this.menu$ = this.store.pipe(select(fromSelectors.getModules));

    this.repairCentres$ = this.store.pipe(select(fromSelectors.getUserRepairCentres));

    this.loadModules();

    this.subMenu$ = this.store.pipe(select(fromSelectors.getSubNav));
    this.customers$ = this.store.pipe(select(fromSelectors.getCustomers));
    if (window.location.pathname === '/activation' || window.location.pathname === '/faq' || window.location.pathname === '/terms-and-conditions' || window.location.pathname === '/privacy-policy') {
      this.isActivation = true;
    }

    this.getCurrentRepairCentreName();
  }

  copyToClipboard(){
    navigator.clipboard.writeText(this.lastCallId);
    this.toastrService.info("Last call id copied to clipboard")
  }

  openTicketPage(id:any) {
    this.viewTicketModalReference.hide();
    this.router.navigateByUrl(`/ticketing/ticket/${id}`)

  }

  closeViewModal() {
    this.viewTicketModalReference.hide();
  }

}
