import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {SalesStaff} from '@appModels/core-fieldforce/sales-staff/sales-staff';

import {CoreFieldforceService} from "@appServices/core-fieldforce/core-fieldforce.service";


@Injectable({providedIn: 'root'})
export class SalesStaffService extends CoreFieldforceService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'salesstaff');
  }

  /*getSalesStaffById(id: number): Observable<SalesStaff> {
    return this.http.get<SalesStaff>(this.base_url + '/' + id, { headers: this.headers }).pipe(
      tap(salesStaffDefinitions => this.log(`fetched sales Staff Definitions`)),
      catchError(this.handleError('getSalesStaffDefinitions', []))
    );
  }
  */
  getSalesStaffById(id: number): Observable<SalesStaff> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<SalesStaff>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffDefinition id=${id}`)),
      catchError(this.handleError<SalesStaff>(`getSalesStaffDefinition id=${id}`))
    );
  }

  getLineManagerChildren(id: number): Observable<any> {
    const url = `${this.base_url}/getLineManagerChildren/${id}`;
    return this.http.post<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffDefinition id=${id}`)),
      catchError(this.handleError<any>(`getSalesStaffDefinition id=${id}`))
    );
  }

  getPossibleLineManagers(id: number): Observable<SalesStaff[]> {
    return this.http.get<SalesStaff[]>(this.base_url + '/getPossibleLineManagers/' + id + '/tenant/' + localStorage.getItem("tenant"), {headers: this.headers}).pipe(
      tap(salesStaffDefinitions => this.log(`fetched sales Staff Definitions`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );
  }

  getPossibleLineManagersPaged(id: number, page: number, pageSize: number, search: String): Observable<any> {
    return this.http.get<any>(this.base_url + '/getPossibleLineManagers/' + id + '/tenant/' + localStorage.getItem("tenant") + '/paged?page=' + page + '&size=' + pageSize + '&search=' + search, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched getPossibleLineManagersPaged`)),
      catchError(this.handleError('getPossibleLineManagersPaged', []))
    );
  }

  getSalesStaffs(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/salesStaffs', {
      params,
      headers: this.headers
    }).pipe(
      tap(salesStaffs => this.log(`fetched salesStaffs`)),
      catchError(this.handleError('getSalesStaffs', []))
    );
  }

  getSalesStafftNo404<Data>(id: number): Observable<SalesStaff> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<SalesStaff[]>(url).pipe(
      map(salesStaff => salesStaff[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} salesStaff id=${id}`);
      }),
      catchError(this.handleError<SalesStaff>(`getStockingPoint id=${id}`))
    );
  }

  getSalesStaffPerformance(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/performance`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffPerformance id=${id}`)),
      catchError(this.handleError<any>(`getSalesStaffPerformance id=${id}`))
    );
  }

  getAssignedCustomer(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/assignedtelesalescustomers', {
      params,
      headers: this.headers
    }).pipe(
      tap(telesalesCustomer => this.log(`fetched telesales customers`)),
      catchError(this.handleError('getTelesalesCustomer', []))
    );
  }

  filterSalesStaff(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    console.log("filtering sales staff");

    params = params.append('tenantId', localStorage.getItem('tenant'));
    return this.http.get<any>(this.base_url + '/salesStaffs', {params, headers: this.headers}).pipe(
      tap(customers => this.log(`fetched customers`))
      ,
      catchError(this.handleError<SalesStaff[]>('getSalesStaff')
      )
    );
  }

  downloadCSV(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '')
          params = params.append(val, query[val]);
      });
    }

    console.log("downloading csv");
    console.log(this.headers);

    params = params.append('tenantId', localStorage.getItem('tenant'));

    return this.http.get(this.base_url + '/downloadCSV', {params, headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched salesStaff vh`)),
      catchError(this.handleError<SalesStaff>(`kkj`))
    );


  }

  /** GET sales staff definitions by id. Will 404 if id not found */
  getSalesStaff(id: number): Observable<SalesStaff> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<SalesStaff>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaff id=${id}`)),
      catchError(this.handleError<SalesStaff>(`getSalesStaff id=${id}`))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchSalesStaff(term: string): Observable<SalesStaff[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }

    const tenantId = localStorage.getItem('tenant');

    return this.http.get<SalesStaff[]>(`${this.base_url}/searchSalesStaff?searchString=${term}&tenantId=${tenantId}`).pipe(
      tap(_ => this.log(`found salesStaff matching "${term}"`)),
      catchError(this.handleError<SalesStaff[]>('salesStaff', []))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchSalesStaffByType(id: number, type: string): Observable<any> {
    if (type == null || type == undefined || type == "") {
      // if not search term, return empty stockingPoint array.
      type = "";
    }
    return this.http.get<any>(this.base_url + '/salesStaffs?tenantId=' + id + '&type=' + type, {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff Definitions`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

    /*return this.http.get<SalesStaff[]>(`corefieldforceservice/api/v1/salesStaffs?type=${type}`).pipe(
      tap(_ => this.log(`found salesStaff matching "${type}"`)),
      catchError(this.handleError<SalesStaff[]>('salesStaff', []))
    );*/
  }

  //////// Save methods //////////

  /** POST: add a new sales staff definition to the server */
  addSalesStaff(salesStaff: any): Observable<SalesStaff> {
    let path = window.location.href;
    let tenantId = localStorage.getItem('tenant');
    path = path.replace(window.location.pathname, '/auth/activate-account');

    return this.http.post<SalesStaff>(this.base_url + `?frontendURL=${path}`, salesStaff, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated sales staff id=`)),
      catchError(this.handleError<SalesStaff>('addSalesStaffDefinition'))
    );
  }

  /** POST: update sales staff status to the server */
  updateSalesStaffStatus(salesStaff: any): Observable<any> {
    return this.http.post<any>(this.base_url + '/changeStatus', salesStaff, {headers: this.headers}).pipe(
      tap(_ => this.log(`change sales staff id=`)),
      catchError(this.handleError<any>('changeSalesStaffDefinition'))
    );
  }

  /** POST: update sales staff status using CSV file to the server */
  updateSalesStaffStatusUsingCsvFile(formData: any): Observable<any> {
    const tenantId = localStorage.getItem('tenant');
    formData.set('tenantId', tenantId);

    this.setHeader(true);

    return this.http.post<any>(this.base_url + '/changeStatusUsingCsv', formData, {headers: this.headers}).pipe(
      tap(_ => this.log(`change sales staff statuses using CSV file`)),
      catchError(this.handleError<any>('changeSalesStaffInBulk'))
    );
  }

  /** POST: add a new sales staff definition to the server */
  addSalesStaffAttributes(salesStaff): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${salesStaff.id}`, salesStaff.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added salesStaff  info `)),
      catchError(this.handleError('addSalesStaff'))
    );
  }

  /** DELETE: delete the sales staff Definition from the server */
  deleteSalesStaff(salesStaff: SalesStaff | number): Observable<SalesStaff> {
    const id = typeof salesStaff === 'number' ? salesStaff : salesStaff.id;
    const url = `${this.base_url}/salesStaff/${id}`;

    return this.http.delete<SalesStaff>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted salesStaff id=${id}`)),
      catchError(this.handleError<SalesStaff>('deleteSalesStaff'))
    );
  }

  /** PUT: update the stockingPoint on the server */
  updateSalesStaff(salesStaff: SalesStaff): Observable<any> {
    return this.http.put(`${this.base_url}/${salesStaff.id}`, salesStaff, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated stockingPoint id=${salesStaff.id}`)),
      catchError(this.handleError<any>('updateSalesStaff'))
    );
  }

  /* GET if there are duplicate fields */
  checkStockingPointDuplicateName(name: string, id: number): Observable<boolean[]> {
    if (id == null)
      id = 0;
    return this.http.get<boolean[]>(this.base_url + 's/validate/checkStockingPointDuplicateName?id=' + id.toString() + '&name=' + name + '&tenantId=' + localStorage.getItem("tenant"), {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

  }

  checkStockingPointDuplicateEmail(name: string, id: number): Observable<boolean[]> {
    if (id == null)
      id = 0;
    return this.http.get<boolean[]>(this.base_url + 's/validate/checkStockingPointDuplicateEmail?id=' + id.toString() + '&email=' + name + '&tenantId=' + localStorage.getItem("tenant"), {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

  }

  checkStockingPointDuplicatePostalAddress(name: string, id: number): Observable<boolean[]> {
    if (id == null)
      id = 0;
    return this.http.get<boolean[]>(this.base_url + 's/validate/checkStockingPointDuplicatePostalAddress?id=' + id.toString() + '&postalAddress=' + name + '&tenantId=' + localStorage.getItem("tenant"), {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

  }

  checkStockingPointDuplicatePhoneNumber(phoneNumber: string, id: number): Observable<boolean[]> {
    if (id == null)
      id = 0;
    return this.http.get<boolean[]>(this.base_url + 's/validate/checkStockingPointDuplicatePhoneNumber?id=' + id.toString() + '&phoneNumber=' + phoneNumber + '&tenantId=' + localStorage.getItem("tenant"), {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

  }

  checkSalesStaffDuplicatePhoneNumber(phoneNumber: string, id: number): Observable<boolean[]> {
    //used for checking duplicate phone number
    if (id == null)
      id = 0;
    return this.http.get<boolean[]>(this.base_url + 's/validate/checkSalesStaffDuplicatePhoneNumber?id=' + id.toString() + '&phoneNumber=' + phoneNumber + '&tenantId=' + localStorage.getItem("tenant"), {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

  }

  /* GET if there are duplicate fields */
  searchSalesAgentPhoneNumber(phoneNumber1: string, phoneNumber2: string): Observable<boolean[]> {
    return this.http.get<boolean[]>(this.base_url + '//searchSalesAgentPhoneNumber?phoneNumber1=' + phoneNumber1 + '&phoneNumber2=' + phoneNumber2 + '&tenantId=' + localStorage.getItem('tenant'), {headers: this.headers}).pipe(
      tap(salesStaff => this.log(`fetched sales Staff`)),
      catchError(this.handleError('getPossibleLineManagers', []))
    );

  }

  /** POST: add a new sales staff definition to the server */
  addSalesStaffToUser(salesStaffId: number, userId: number): Observable<SalesStaff> {
    return this.http.patch<SalesStaff>(`${this.base_url}/${salesStaffId}/users/${userId}`, '', {headers: this.headers}).pipe(
      tap(_ => this.log(`updated sales staff id=`)),
      catchError(this.handleError<SalesStaff>('addSalesStaffDefinition'))
    );
  }

  /** POST: update line managers */
  updateLineManager(payload, file?): Observable<any> {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }
    formData.append('data', new Blob([JSON.stringify(payload)], {
      type: 'application/json'
    }));

    this.setHeader(true);


    return this.http.post(`${this.base_url}/updatelinemanagers`, formData, {headers: this.headers}).pipe(
      tap((res) => this.log(`update line managers`)),
      catchError(this.handleError('updateLineManagers'))
    );
  }


  getMonthlySalesPerformance(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/monthlysalesperformance`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched monthly sales performance id=${id}`)),
      catchError(this.handleError<SalesStaff>(`getMonthlySalesPerformance id=${id}`))
    );
  }

  uploadSalesStaffAttachment(salesStaffId: number, attachmentType: string, formData: any): Observable<any> {
    this.setHeader(true);
    return this.http.post<any>(`${this.base_url}/${salesStaffId}/attachmentType/${attachmentType}/upload`, formData, {headers: this.headers}).pipe(
      tap(_ => this.log(`uploadSalesStaffAttachment`)),
      catchError(this.handleError<any>('uploadSalesStaffAttachment'))
    );
  }

  getSalesStaffAttachments(salesStaffId: number): Observable<any> {
    const url = `${this.base_url}/${salesStaffId}/attachments`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getSalesStaffAttachments=${salesStaffId}`)),
      catchError(this.handleError<SalesStaff>(`getSalesStaffAttachments id=${salesStaffId}`))
    );
  }

  downloadSalesStaffAttachment(attachmentId: number): Observable<any> {
    const url = `${this.base_url}/attachment/${attachmentId}`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`downloadFile`)),
      catchError(this.handleError<any>(`downloadFile`))
    );
  }

  getSecurityDepositPayments(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/transactions/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(_ => this.log(`fetched SecurityDepositPayments`)),
      catchError(this.handleError('getSecurityDepositPayments', []))
    );
  }


  getClawbackTypes( salesStaffId : number): Observable<any> {
    const url = `${this.base_url}/${salesStaffId}/clawbackTypes`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getClawbackTypes`)),
      catchError(this.handleError<any>(`getClawbackTypes`))
    );
  }

  getTotalClawback( salesStaffId : number): Observable<any> {
    const url = `${this.base_url}/${salesStaffId}/clawbackAmountTotal`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getClawbackTypes`)),
      catchError(this.handleError<any>(`getClawbackTypes`))
    );
  }

  getSalesStaffByUserId( userId : number): Observable<any> {
    const url = `${this.base_url}/user/${userId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`getSalesStaffByUserId`)),
      catchError(this.handleError<any>(`getSalesStaffByUserId`))
    );
  }

  /** POST: transfer subordinates to a new line manager */
  transferToNewLineManager(salesStaffIds: number[], currentLineManagerId: number, newLineManagerId: number): Observable<any> {
    const payload = {
      salesStaffIds: salesStaffIds,
      currentLineManagerId: currentLineManagerId,
      newLineManagerId: newLineManagerId
    };


    return this.http.post(`${this.base_url}/transferToNewLineManager`, payload, { headers: this.headers }).pipe(
      tap(() => this.log(`transfer subordinates to new line manager`)),
      catchError(this.handleError('transferToNewLineManager'))
    );
  }

}


